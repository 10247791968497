
.markdown-editor {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    justify-content: center;
    align-items: center;
}

.editor-container {
    flex: 1;
    margin-bottom: 10px;
    width: 100%;
}

textarea {
    width: 100%;
    min-height: 200px;
    padding: 10px;
    font-family: "Arial", sans-serif;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    box-sizing: border-box;
    height: 70vh;
}

.toggle-button {
    align-self: flex-end;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.toggle-button:hover {
    background-color: #0056b3;
}

.preview {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.preview {
    border-collapse: collapse;
    width: 100%; /* Adjust width as needed */
}

.preview th,
.preview td {
    padding: 5px 10px;
    border: 1px solid #ddd;
}

.preview th {
    background-color: #f5f5f5;
    text-align: left; /* Adjust alignment as needed */
}

.preview tr:nth-child(even) {
    background-color: #fafafa;
}

.preview img{
    max-height: 300px;
    display: block;
    margin: 0 auto;
}

.cms-submit {
    border-radius: 10px;
    border: none;
    padding: 1em 2em;
    background-color: rgb(255, 208, 0);
    margin-top: 1em;
    width: 50%;
}

#title {
    padding: 1em 3em 1em 1em;
    border-radius: 10px;
    border: 1px solid gray;
}

button:hover{
    cursor: pointer;
}

#images_container{
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px;
    border: 1px solid black;
    margin: 1em;
    border-radius: 1em;
    resize: horizontal;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.733);
}

#images_container .list{
    width: 100%;
    height: 300px;
    background-color: rgba(210, 210, 210, 0.781);
    margin-bottom: 10px;
    overflow-y: auto;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
}

#images_container .list img{
    width: 100px;
    height: 100px;
    background-color: rgba(210, 210, 210, 0.781);
    margin-bottom: 10px;
    overflow-y: auto;
    border-radius: 10px;
    cursor: pointer;
    object-fit: cover;
}

.milkdown{
    border: 1px solid black;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 20px;
    margin-top: 1em;
}